var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav',{staticClass:"tabs"},[_c('ul',[_c('router-link',{attrs:{"to":"/settings/clients","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && "is-active"]},[_c('a',{attrs:{"href":href}},[_c('b-icon',{attrs:{"icon":"download"}}),_vm._v("torrent clients")],1)])]}}])}),_c('router-link',{attrs:{"to":"/settings/notifications","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && "is-active"]},[_c('a',{attrs:{"href":href}},[_c('b-icon',{attrs:{"icon":"alert-circle"}}),_vm._v("notifications")],1)])]}}])}),_c('router-link',{attrs:{"to":"/settings/beets","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isActive = ref.isActive;
return [_c('li',{class:[isActive && "is-active"]},[_c('a',{attrs:{"href":href}},[_c('b-icon',{attrs:{"icon":"note"}}),_vm._v("beets config")],1)])]}}])})],1)]),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }